/*eslint-disable*/
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, Card, Stack } from "@mui/material";

export default ({ message = "Payment was successful", handlePage }) => {
    return (
        <>
            <Card sx={{ borderRadius: "10px" }}>
                <Box sx={{ p: 3, width: "100%" }}>
                    <Stack spacing={2}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CheckCircleIcon color="success" fontSize="large" />
                        </div>
                        <h3 className="text-blueGray-500 font-bold text-center mb-3">
                            <strong>{message}</strong>
                        </h3>
                        {handlePage ?
                        <Button 
                            fullWidth
                            variant="contained" 
                            size="small"
                            onClick={handlePage}
                        >
                            <b>Go back to first page</b>
                        </Button> 
                        : null}
                    </Stack>
                </Box>
            </Card>
        </>
    );
};
