/*eslint-disable*/
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

import { apiCall, toggleLoading } from "store/actions";
import { MAKE_PAGE_PAYMENT, LOGIN_WITH_OTP } from "graphql";
import { Box, Button, Card, OutlinedInput, Stack } from "@mui/material";

export default ({ paymentDetails, handlePage }) => {
    const dispatch = useDispatch();

    const handleOtpSubmit = async (data) => {
        dispatch(toggleLoading(true));
        const response = await apiCall(paymentDetails.baseUrl, LOGIN_WITH_OTP, {
            otp: data.otp,
            email: paymentDetails.email,
        });

        if (response.status) {
            dispatch(toggleLoading(false));
            return handlePayment();
        }

        toast.error("Incorrect OTP");

        dispatch(toggleLoading(false));
    };

    const handlePayment = async () => {
        dispatch(toggleLoading(true));
        const response = await apiCall(paymentDetails.baseUrl, MAKE_PAGE_PAYMENT, { paymentId: paymentDetails.paymentId });
        if (!response.status) {
            dispatch(toggleLoading(false));
            return handlePage(5);
        }

        dispatch(toggleLoading(false));
        return handlePage(6);
    };

    return (
        <>
            <Card sx={{ borderRadius: "10px" }}>
                <Box sx={{ p: 3, width: "100%" }}>
                    <Stack spacing={3}>
                        <Formik
                            initialValues={{
                                otp: "",
                            }}
                            validationSchema={Yup.object().shape({
                                otp: Yup.string().required("OTP is required"),
                            })}
                            onSubmit={(value) => handleOtpSubmit(value)}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                <form style={{ paddingTop: "2em" }} onSubmit={handleSubmit}>
                                    <Stack spacing={2}>
                                        <Stack>
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                OTP
                                            </label>
                                            <OutlinedInput
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="otp"
                                                placeholder="Enter OTP"
                                                value={values.otp}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {Boolean(touched.otp && errors.otp) ? (
                                                <div
                                                    style={{
                                                        display: "block",
                                                        marginLeft: "10px",
                                                        color: "red",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {errors.otp}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </Stack>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            onClick={handleSubmit}
                                            disabled={values.otp === ""}
                                        >
                                            <b>Continue</b>
                                        </Button>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </Stack>
                </Box>
            </Card>
        </>
    );
};
