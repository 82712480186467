/*eslint-disable*/
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";

import { apiCall, toggleLoading } from "store/actions";
import { CREATE_NEW_ORDER } from "graphql";
import { Box, Button, Card, OutlinedInput, Stack, Typography } from "@mui/material";

export default ({ handlePage, handlePaymentDetails, paymentDetails }) => {
    const dispatch = useDispatch();

    const handleContinue = async (data) => {
        dispatch(toggleLoading(true));
        data.merchantId = "6360dc558ad8c8c6c93041dc";

        let network = "solana";
        if (data.currency.length) {
            network = data.currency[0].value;
        }
        data.network = network;

        const response = await apiCall(paymentDetails.baseUrl, CREATE_NEW_ORDER, data);
        if (!response.status) {
            toast.error("Failed to initiate order");
            dispatch(toggleLoading(false));
            return;
        }
        const { order_id, wallet_address, is_bal_sufficient, acc_balance, amount_required } =
            response.data?.create_new_order;

        handlePaymentDetails({
            orderId: order_id,
            walletAddress: wallet_address,
            amount: data.amount,
            network: network,
            isBalSufficient: is_bal_sufficient,
            balance: acc_balance,
            amountReq: amount_required,
            email: data.email,
        });
        dispatch(toggleLoading(false));
        return handlePage(2);
    };

    return (
        <>
            <Card sx={{ borderRadius: "10px" }}>
                <Box sx={{ p: 3, width: "100%" }}>
                    <Stack spacing={3}>
                        <Typography sx={{ textAlign: "center" }} variant='sutitle2'>
                            <b>Payment to merchant</b>
                            <hr />
                        </Typography>
                        <Formik
                            initialValues={{
                                email: "",
                                currency: [{ value: "solana", label: "Solana" }],
                                amount: 0.02,
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                            })}
                            onSubmit={(value) => handleContinue(value)}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                <form style={{ paddingTop: "2em" }} onSubmit={handleSubmit}>
                                    <Stack spacing={2}>
                                        <Stack spacing={1}>
                                            <label className="uppercase text-blueGray-600 text-xs font-bold">
                                                Currency
                                            </label>
                                            <Select
                                                key="currency"
                                                name="currency"
                                                defaultValue={[{ value: "solana", label: "Solana" }]}
                                                options={[
                                                    { value: "solana", label: "Solana" },
                                                    { value: "sol_usdc", label: "USDC (solana chain)" },
                                                    { value: "sol_usdt", label: "USDT (solana chain)" },
                                                    // { value: "ethereum", label: "Ethereum (ethereum chain)" },
                                                    // { value: "avalanche", label: "Avalanche (ethereum chain)" },
                                                    // { value: "polygon", label: "Polygon Matic (ethereum chain)" },
                                                    // { value: "eth_usdc", label: "USDC (ethereum chain)" },
                                                ]}
                                                isOptionDisabled={(option) => option.disabled}
                                                isClearable={false}
                                                value={values.currency}
                                                onChange={(selectedOption) => {
                                                    let event = { target: { name: "currency", value: [selectedOption] } };
                                                    handleChange(event);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                        </Stack>
                                        <Stack spacing={1}>
                                            <label className="uppercase text-blueGray-600 text-xs font-bold">
                                                Amount
                                            </label>
                                            <OutlinedInput
                                                fullWidth
                                                size="small"
                                                type="number"
                                                name="amount"
                                                placeholder="Enter amount"
                                                value={values.amount}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                min={0.02}
                                            />
                                        </Stack>
                                        <Stack spacing={1}>
                                            <label className="uppercase text-blueGray-600 text-xs font-bold">
                                                Email
                                            </label>
                                            <OutlinedInput
                                                fullWidth
                                                size="small"
                                                type="email"
                                                name="email"
                                                placeholder="Enter email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {Boolean(touched.email && errors.email) ? (
                                                <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                                    {errors.email}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </Stack>
                                        <Stack>
                                            <Button variant="contained"
                                                type="submit"
                                                onClick={handleSubmit}
                                                disabled={values.email === "" || values.amount < 0.02}
                                            >
                                                <b>Continue</b>
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </Stack>
                </Box>
            </Card>
        </>
    );
};
