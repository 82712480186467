/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { apiCall } from "store/actions";
// import { CHECK_FOR_ORDER_TXN } from "graphql";
import { CHECK_FOR_PAGE_TXN } from "graphql";
import { getBlockchainCurrency, getBlockchainNetwork } from "utils";
import "../payment/countdown.css";

import { SEND_OTP } from "graphql";
import { toggleLoading } from "store/actions";
import { Box, Button, Card, Stack, Typography } from "@mui/material";

export default ({ paymentDetails, handlePage }) => {
    const dispatch = useDispatch();

    const [minutes, setMinutes] = useState(9);
    const [seconds, setSeconds] = useState(59);
    const [apiCallCount, setApiCallCount] = useState(0);
    const [copied, setCopied] = useState(false);
    const [accBalance, setAccBalance] = useState(paymentDetails.balance)
    const [isBalSufficient, setIsBalSufficient] = useState(paymentDetails.isBalSufficient);

    useEffect(() => {
        if (!minutes && !seconds) return;
        const intervalId = setInterval(() => {
            if (seconds) {
                setSeconds(seconds - 1);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [seconds]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiCall(paymentDetails.baseUrl, CHECK_FOR_PAGE_TXN, {
                paymentId: paymentDetails.paymentId,
            });
            setApiCallCount(apiCallCount + 1);

            if (!response.status) {
                return;
            }

            const {
                check_for_page_txn: { is_bal_sufficient, acc_balance },
            } = response.data;

            if (acc_balance != accBalance){
                setAccBalance(acc_balance)
            }
            setIsBalSufficient(is_bal_sufficient);
        };

        const timer = setTimeout(() => {
            if (!isBalSufficient) {
                fetchData();
            }
        }, 15000);

        return () => clearTimeout(timer);
    }, [apiCallCount]);

    const handleContinue = async () => {
        dispatch(toggleLoading(true));
        const sendOtp = await apiCall(paymentDetails.baseUrl, SEND_OTP, { email: paymentDetails.email });
        // toast.success(message ?? "Transaction was successful");
        if (sendOtp.status) {
            dispatch(toggleLoading(false));
            return handlePage(4);
        }

        toast.error("Failed to send confirmation OTP");
        return dispatch(toggleLoading(false));
    };

    return (
        <>
            <Card sx={{ borderRadius: "10px" }}>
                <Box sx={{ p: 3, width: "100%" }}>
                    <Stack spacing={2}>
                        {/* <Typography sx={{ textAlign: "center" }} variant='sutitle2'>
                            <b>{paymentDetails.orderId}</b>
                            <hr />
                        </Typography> */}
                        <Stack>
                            <div
                                style={{ padding: 10, borderRadius: "10px", backgroundColor: "#f0f0f0", margin: 0 }}
                                className="amount"
                            >
                                <div style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }} className="box">
                                    <img src={getBlockchainNetwork(paymentDetails.network)} />
                                </div>
                                <div className="content">
                                    <h3>Transaction amount:</h3>
                                    <b style={{ textTransform: "uppercase" }}>
                                        {paymentDetails.amount} {getBlockchainCurrency(paymentDetails.network)}
                                    </b>
                                    <br />
                                </div>
                            </div>
                        </Stack>
                        <Stack spacing={1}>
                            <label className="uppercase text-blueGray-600 text-xs font-bold">
                                Amount available in your wallet
                            </label>
                            <div className="id-view">
                                <div className="id">{accBalance}</div>
                            </div>
                        </Stack>
                        {!isBalSufficient ? (
                            <Stack>
                                <label className="uppercase text-blueGray-600 text-xs font-bold">Amount required</label>
                                <div className="id-view" style={{ textTransform: "uppercase" }}>
                                    <div className="id">
                                        {paymentDetails.amountReq} {getBlockchainCurrency(paymentDetails.network)}
                                    </div>
                                </div>
                            </Stack>
                        ) : null}
                        <Stack>
                            <div className="id-view">
                                {/* <div className="title font-bold">wallet address</div>  */}
                                <div className="id">{paymentDetails.walletAddress}</div>
                                <CopyToClipboard text={paymentDetails.walletAddress} onCopy={() => setCopied(true)}>
                                    <button style={{ backgroundColor: !copied ? "rgb(0, 81, 255)" : "#6BC654" }}>
                                        {!copied ? "Copy" : "copied"}
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </Stack>
                        <Stack>
                            <div style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#f0f0f0", margin: 0 }}>
                                <Typography variant="body2">
                                    You need to carry out a transaction of{" "}
                                    <strong style={{ textTransform: "uppercase" }}>
                                        {paymentDetails.amount} {getBlockchainCurrency(paymentDetails.network)}
                                    </strong>{" "}
                                    to the above wallet address
                                </Typography>
                                <Typography variant="body2">
                                    Once you have loaded the wallet enter OTP to complete the transaction.
                                </Typography>
                            </div>
                        </Stack>
                        <Stack>
                            <Button variant="contained" onClick={handleContinue} disabled={!isBalSufficient}>
                                <b>Continue</b>
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Card>
        </>
    );
};
