import solana from "../assets/icons/solana.png";
import etherium from "../assets/icons/ethereum.png";
import avalanche from "../assets/icons/avalanche.png";
import polygon from "../assets/icons/polygon.png";
import usdc from "../assets/icons/usdc.png";
import usdt from "../assets/icons/usdt.png";

export const getBlockchainCurrency = (network = "solana") => {
    if (network === "solana") {
        return "sol";
    } else if (network === "ethereum") {
        return "eth";
    } else if (network === "avalanche") {
        return "avax";
    } else if (network === "polygon") {
        return "matic";
    } else if (network === "eth_usdc" || network === "sol_usdc") {
        return "usdc";
    } else if (network === "sol_usdt") {
        return "usdt";
    } else {
        return "sol";
    }
};

export const getCurrencyName = (network = "sol") => {
    if (network === "sol") {
        return "solana";
    } else if (network === "eth") {
        return "ethereum";
    } else if (network === "avax") {
        return "avalanche";
    } else if (network === "matic") {
        return "polygon";
    } else if (network === "usdc") {
        return "sol_usdc";
    } else if (network === "usdt") {
        return "sol_usdt";
    } else {
        return "solana";
    }
};

export const getBlockchainNetwork = (currency = "sol") => {
    if (currency === "sol" || currency === "solana") {
        return solana;
    } else if (currency === "eth" || currency === "ethereum") {
        return etherium;
    } else if (currency === "avax" || currency === "avalanche") {
        return avalanche;
    } else if (currency === "matic" || currency === "polygon") {
        return polygon;
    } else if (currency === "eth_usdc" || currency === "sol_usdc") {
        return usdc;
    } else if (currency === "sol_usdt") {
        return usdt;
    } else {
        return solana;
    }
};
