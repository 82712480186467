/*eslint-disable*/
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";

import { apiCall, toggleLoading } from "store/actions";
import { UPDATE_PLINK } from "graphql";
import { cryptoConverter, getBlockchainNetwork, getCurrencyName, getBlockchainCurrency } from "utils";
import { Box, Button, Card, InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";
import { Email } from "@mui/icons-material";

export default ({ dispatch, handlePage, handlePaymentDetails, paymentDetails }) => {
    const [amount, setAmount] = useState(0);
    const [convertTo, setConvertTo] = useState(getCurrencyName(paymentDetails.currency));

    const handleContinue = async (data) => {
        dispatch(toggleLoading(true));
        data.plinkId = paymentDetails.plinkId;

        let network = "solana";
        if (data.currency.length) {
            network = data.currency[0].value;
        }
        data.network = network;
        data.amount = amount;

        const response = await apiCall(paymentDetails.baseUrl, UPDATE_PLINK, data);
        if (!response.status) {
            toast.error("Failed to initiate transaction");
            return dispatch(toggleLoading(false));
        }
        const { wallet_address, is_bal_sufficient, acc_balance, amount_required } = response.data?.update_payment_link;

        handlePaymentDetails({
            ...paymentDetails,
            walletAddress: wallet_address,
            network: network,
            amount: amount,
            isBalSufficient: is_bal_sufficient,
            balance: acc_balance,
            amountReq: amount_required,
            email: data.email,
        });
        dispatch(toggleLoading(false));
        return handlePage(2);
    };

    useEffect(async () => {
        setAmount(await cryptoConverter(paymentDetails.currency, convertTo, paymentDetails.amount));
    }, [convertTo]);

    const generateOptions = (value = "") => {
        let options = [
            { value: "solana", label: "Solana" },
            { value: "sol_usdc", label: "USDC (solana chain)" },
            { value: "sol_usdt", label: "USDT (solana chain)" },
            // { value: "ethereum", label: "Ethereum (ethereum chain)" },
            // { value: "avalanche", label: "Avalanche (ethereum chain)" },
            // { value: "polygon", label: "Polygon Matic (ethereum chain)" },
            // { value: "eth_usdc", label: "USDC (ethereum chain)" },
        ];
        if (!value) {
            return options;
        } else {
            return options.filter((e) => e.value === value);
        }
    };

    return (
        <>
            <Card sx={{ borderRadius: "10px" }}>
                <Box sx={{ p: 3, width: "100%" }}>
                    <Stack spacing={2}>
                        <Typography className="text-blueGray-600 text-sm text-center" variant='sutitle2'>
                            Payment Request from <strong>{paymentDetails?.merchant}</strong>
                            <hr className="mt-3 border-b-1 border-blueGray-300" />
                        </Typography>
                        <div style={{ padding: 10, marginBottom: 10, borderRadius: "10px", backgroundColor: "#f0f0f0" }} className="amount">
                            <div style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }} className="box">
                                <img src={getBlockchainNetwork(convertTo)} />
                            </div>
                            <div className="content">
                                <h3>Amount payable:</h3>
                                <b style={{ textTransform: "uppercase" }}>
                                    {/* {paymentDetails.amount} {paymentDetails.currency}{" "} */}
                                    {amount} {getBlockchainCurrency(convertTo)}
                                </b>
                                <br />
                                <h3>Payment for:</h3>
                                {paymentDetails.paymentFor ? <h5>{paymentDetails.paymentFor}</h5> : "-"}
                            </div>
                        </div>
                        <Formik
                            initialValues={{
                                email: "",
                                currency: generateOptions(getCurrencyName(paymentDetails.currency)),
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                            })}
                            onSubmit={(value) => {
                                return new Promise((resolve, reject) => {
                                    handleContinue(value).then(() => {
                                        resolve({});
                                    });
                                });
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <Stack spacing={3}>

                                        <Stack>
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Currency
                                            </label>
                                            <Select
                                                key="currency"
                                                name="currency"
                                                defaultValue={generateOptions(getCurrencyName(paymentDetails.currency))}
                                                options={generateOptions()}
                                                isOptionDisabled={(option) => option.disabled}
                                                isClearable={false}
                                                value={values.currency}
                                                onChange={(selectedOption) => {
                                                    let event = { target: { name: "currency", value: [selectedOption] } };
                                                    handleChange(event);
                                                    setConvertTo(selectedOption.value);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                        </Stack>

                                        <Stack spacing={1}>
                                            <label className="uppercase text-blueGray-600 text-xs font-bold">
                                                Email
                                            </label>
                                            <Stack sx={{ width: "100%" }}>
                                                <OutlinedInput
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <Email fontSize="12px" />
                                                        </InputAdornment>
                                                    }
                                                />
                                                {Boolean(touched.email && errors.email) ? (
                                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                                        {errors.email}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </Stack>
                                        </Stack>

                                        <Stack>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                onClick={handleSubmit}
                                                disabled={values.email === "" || amount <= 0}
                                            >
                                                <b>Continue</b>
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </Stack>
                </Box>
            </Card>
        </>
    );
};
