/*eslint-disable*/
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";

import { apiCall, toggleLoading } from "store/actions";
import { UPDATE_ORDER_DETAILS } from "graphql";
import { cryptoConverter, getBlockchainNetwork, getCurrencyName, getBlockchainCurrency } from "utils";

export default ({ dispatch, handlePage, handlePaymentDetails, paymentDetails }) => {
    const [amount, setAmount] = useState(0);
    const [convertTo, setConvertTo] = useState(getCurrencyName(paymentDetails.currency));

    const handleContinue = async (data) => {
        dispatch(toggleLoading(true));
        data.orderId = paymentDetails.orderId;

        let network = "solana";
        if (data.currency.length) {
            network = data.currency[0].value;
        }
        data.network = network;
        data.amount = amount;

        const response = await apiCall(paymentDetails.baseUrl, UPDATE_ORDER_DETAILS, data);
        if (!response.status) {
            toast.error("Failed to initiate transaction");
            return dispatch(toggleLoading(false));
        }
        const { wallet_address } = response.data?.update_order_details;

        handlePaymentDetails({ ...paymentDetails, walletAddress: wallet_address, network: network, amount: amount });
        dispatch(toggleLoading(false));
        return handlePage(2);
    };

    useEffect(async () => {
        setAmount(await cryptoConverter(paymentDetails.currency, convertTo, paymentDetails.amount));
    }, [convertTo]);

    const generateOptions = (value = "") => {
        let options = [
            { value: "solana", label: "Solana" },
            { value: "sol_usdc", label: "USDC (solana chain)" },
            { value: "sol_usdt", label: "USDT (solana chain)" },
            // { value: "ethereum", label: "Ethereum (ethereum chain)" },
            // { value: "avalanche", label: "Avalanche (ethereum chain)" },
            // { value: "polygon", label: "Polygon Matic (ethereum chain)" },
            // { value: "eth_usdc", label: "USDC (ethereum chain)" },
        ];
        if (!value) {
            return options;
        } else {
            return options.filter((e) => e.value === value);
        }
    };

    return (
        <>
            <div className="rounded-t mb-0 px-6 py-6">
                <div>
                    <h6 className="text-blueGray-500 text-sm font-bold text-center mb-3">
                        Payment to <strong>{paymentDetails?.merchant}</strong>
                    </h6>
                    <div className="amount">
                        <div className="box">
                            <img src={getBlockchainNetwork(convertTo)} />
                        </div>
                        <div className="content">
                            <h3>Amount payable:</h3>
                            <b style={{ textTransform: "uppercase" }}>
                                {/* {paymentDetails.amount} {paymentDetails.currency}{" "} */}
                                {amount} {getBlockchainCurrency(convertTo)}
                            </b>
                        </div>
                    </div>
                    {/* <h6>
                        Amount Payable: <strong>{paymentDetails.amount} SOL</strong>
                    </h6>
                    {paymentDetails.paymentFor ? <h5>Payment For: {paymentDetails.paymentFor}</h5> : null} */}
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div className="fields">
                <Formik
                    initialValues={{
                        email: "",
                        currency: generateOptions(getCurrencyName(paymentDetails.currency)),
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                    })}
                    onSubmit={(value) => {
                        return new Promise((resolve, reject) => {
                            handleContinue(value).then(() => {
                                resolve({});
                            });
                        });
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {Boolean(touched.email && errors.email) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.email}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Currency
                                </label>
                                <Select
                                    key="currency"
                                    name="currency"
                                    defaultValue={generateOptions(getCurrencyName(paymentDetails.currency))}
                                    options={generateOptions()}
                                    isOptionDisabled={(option) => option.disabled}
                                    isClearable={false}
                                    value={values.currency}
                                    onChange={(selectedOption) => {
                                        let event = { target: { name: "currency", value: [selectedOption] } };
                                        handleChange(event);
                                        setConvertTo(selectedOption.value);
                                    }}
                                    onBlur={handleBlur}
                                />
                            </div>

                            <div className="text-center mt-6">
                                <button
                                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={values.email === "" || amount <= 0}
                                >
                                    Continue
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );
};
