/*eslint-disable*/
import React, { useState } from "react";
// import { useDispatch } from "react-redux";

//components
import { Modal } from "@mui/material";
import FirstPage from "./first-page";
import SecondPage from "./countdown";
import OtpPage from "./confirmation";
import SuccessPage from "../common/successPage";
import ErrorPage from "../common/errorPage";

import { fetchBaseURL } from "utils";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    outline: "none",
    boxShadow: 24,
    p: 4,
};

export default function ({ loading }) {
    const [page, setPage] = useState(1);
    const [details, setDetails] = useState({
        orderId: "",
        walletAddress: "",
        amount: 0.01,
        network: "",
        baseUrl: fetchBaseURL(),
        isBalSufficient: false,
        balance: 0,
        amountReq: 0,
        email: "",
    });

    const handlePage = (data) => {
        setPage(data);
    };

    const handlePaymentDetails = (data) => {
        setDetails(data);
    };

    return (
        <>
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-4/12 px-4">
                        <Modal open={!loading}>
                            <div
                                style={style}
                                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
                            >
                                {page === 1 ? (
                                    <FirstPage
                                        handlePage={handlePage}
                                        handlePaymentDetails={handlePaymentDetails}
                                        paymentDetails={details}
                                    />
                                ) : page === 2 ? (
                                    <SecondPage handlePage={handlePage} paymentDetails={details} />
                                ) : page === 3 ? (
                                    <OtpPage paymentDetails={details} handlePage={handlePage} />
                                ) : page === 4 ? (
                                    <SuccessPage handlePage={() => handlePage(1)} />
                                ) : page === 5 ? (
                                    <ErrorPage />
                                ) : null}
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
}
