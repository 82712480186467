export const UPDATE_PLINK = `mutation ($email: String!, $plinkId: String!, $network: String $amount: Float) {
  update_payment_link(email: $email, plink_id: $plinkId, network: $network, amount: $amount) {
    message
    wallet_address
    is_bal_sufficient
    amount_required
    acc_balance
  }
}

  `;

export const CHECK_FOR_PLINK_TXNS = `query ($plinkId: String!) {
    check_for_plink_txns(plink_id: $plinkId) {
      status
      message
    }
  }
  `;

export const MAKE_PLINK_TXN = `mutation ($plinkId: String!) {
    create_plink_transaction(plink_id: $plinkId) {
      message
    }
  }
  `;

export const FETCH_PAYMENT_LINK_DETAILS = `query ($plinkId: String!) {
  list_payment_link_details(plink_id: $plinkId) {
    message
    data {
      _id
      plink_id
      amount
      currency
      created_at
      status
      expiry
      payment_for
      user_id {
        _id
        name
        email
      }
      merchant_id {
        name
        email
      }
      notes {
        title
        description
      }
    }
  }
}
`;

export const CHECK_FOR_PLINK = `query ($plinkId: String!) {
  check_for_plink(plink_id: $plinkId) {
    is_bal_sufficient
    amount_required
    acc_balance
  } 
}`;
